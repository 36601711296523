import React from 'react'

export default function UsersFilter({ setIsVerified, users, setSortedUser, isVerified, isAZsorted, setIsAZsorted }) {

    const sortHandler = () => {
        setIsAZsorted(!isAZsorted)
        if (!isAZsorted) {
            let sortedUsers = [...users];
            sortedUsers.sort((a, b) => {
                const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
                const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
                return nameA.localeCompare(nameB);
            });
            setSortedUser(sortedUsers);
        } else {
            setSortedUser(null)
        }
    }

    return (
        <div style={{ margin: "0px 100px" }}>
            <div className='input-checkbox'>
                <input checked={isVerified} onChange={() => setIsVerified(!isVerified)} type='checkbox' />
                <p>Verified Users</p>
            </div>
            <div className='input-checkbox'>
                <input onChange={() => sortHandler()} type='checkbox' checked={isAZsorted} />
                <p>Sort Users (A-Z)</p>
            </div>
        </div>
    )
}