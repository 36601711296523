import React, { useEffect, useState } from 'react'
import axiosInstance from '../../config/ProtectedAxios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import UsersFilter from '../../components/AdminDashboard/UsersFilter';

export default function Users() {
    const userToken = localStorage.getItem("userToken");
    const [users, setUsers] = useState(undefined);
    const [isChanged, setIsChanged] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isAZsorted, setIsAZsorted] = useState(false);
    const protectedAxios = axiosInstance(userToken);
    const [usercount, setUserCount] = useState(20);
    const [SortedUser, setSortedUser] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUsers = users?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const fetchUsers = async () => {
        try {
            const res = await protectedAxios.post(`/getusers`, {
                isVerified,
                isLatestCreated: true,
                usercount
            });
            setUsers(res.data);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [isVerified])


    return (
        <div style={{ margin: "100px auto" }}>
            <h1 id="head_dash" style={{ margin: "30px 150px" }}>
                Users
            </h1>

            <UsersFilter 
                users={users} 
                setSortedUser={setSortedUser} 
                isAZsorted={isAZsorted} 
                isVerified={isVerified} 
                setIsAZsorted={setIsAZsorted} 
                setIsVerified={setIsVerified} 
            />

            <div
                className="shadow-box admin-box"
                style={{ width: "74%", marginTop: "10px" }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                ></div>
                <hr style={{ marginBottom: "20px" }} />
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Profile</th>
                                <th>Country</th>
                                <th>CreatedOn</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isAZsorted && SortedUser === null && currentUsers?.map((user, index) => (
                                <tr key={index}>
                                    <td>
                                        {user.profileType === "ngo" ||
                                            user.profileType === "impactOrg" ||
                                            user.profileType === "business"
                                            ? user.businessName
                                            : user?.firstName + " " + user?.lastName}
                                    </td>
                                    <td>
                                        {user.email}
                                    </td>
                                    <td>
                                        {user.profileType}
                                    </td>
                                    <td>
                                        {user.country}
                                    </td>
                                    <td>
                                        {moment(user.createdDate).format("DD-MM-YYYY hh:mm A")}
                                    </td>
                                </tr>
                            ))}
                            {isAZsorted && SortedUser !== null && SortedUser?.map((user, index) => (
                                <tr key={index}>
                                    <td>
                                        {user.profileType === "ngo" ||
                                            user.profileType === "impactOrg" ||
                                            user.profileType === "business"
                                            ? user.businessName
                                            : user?.firstName + " " + user?.lastName}
                                    </td>
                                    <td>
                                        {user.email}
                                    </td>
                                    <td>
                                        {user.profileType}
                                    </td>
                                    <td>
                                        {user.country}
                                    </td>
                                    <td>
                                        {moment(user.createdDate).format("DD-MM-YYYY hh:mm A")}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(users?.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                    />
                </div>
            </div>

        </div>
    )
}