import React, { useEffect, useState } from 'react'
import axiosInstance from '../../config/ProtectedAxios';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

export default function RecentTransactions() {
    const userToken = localStorage.getItem("userToken");
    const [Transactions, setTransactions] = useState(undefined);
    const protectedAxios = axiosInstance(userToken);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTransactions = Transactions?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const fetchUsers = async () => {
        try {
            const res = await protectedAxios.get(`/recent/transactions`);
            setTransactions(res.data.recentTransactions);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <div style={{ margin: "100px auto" }}>
            <h1 id="head_dash" style={{ margin: "30px 150px" }}>
                Transactions
            </h1>

            <div
                className="shadow-box admin-box"
                style={{ width: "74%", marginTop: "10px" }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                ></div>
                <hr style={{ marginBottom: "20px" }} />
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>Sender</th>
                                <th>Receiver</th>
                                <th>amount</th>
                                <th>paymentMode</th>
                                <th>Status</th>
                                <th>Created On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTransactions?.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        {item.senderId}
                                    </td>
                                    <td>
                                        {item.recipientUserId}
                                    </td>
                                    <td>
                                        {item.amount}
                                    </td>
                                    <td>
                                        {item.paymentMode}
                                    </td>
                                    <td>
                                        {item.transactionStatus}
                                    </td>
                                    <td>
                                        {moment(item.createdDate).format("DD-MM-YYYY hh:mm A")}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(Transactions?.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                    />
                </div>
            
            </div>
        </div>
    )
}