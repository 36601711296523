import { useState, useEffect, useRef } from 'react';

function useThrottle(value, delay) {
    const [throttledValue, setThrottledValue] = useState(value);
    const lastTimeoutRef = useRef(null);

    useEffect(() => {
        if (lastTimeoutRef.current) {
            clearTimeout(lastTimeoutRef.current);
        }

        lastTimeoutRef.current = setTimeout(() => {
            setThrottledValue(value);
        }, delay);

        return () => {
            if (lastTimeoutRef.current) {
                clearTimeout(lastTimeoutRef.current);
            }
        };
    }, [value, delay]);

    return throttledValue;
}

export default useThrottle;
