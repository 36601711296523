import React, { useState } from 'react'
import SearchBar from '../../components/AdminDashboard/SearchBar';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Loader from '../common/Loader';

export default function SearchUser() {
  const [searchedUsers, setSearchedUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = searchedUsers?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div style={{ margin: "100px auto" }}>
      <h1 id="head_dash" style={{ margin: "30px 150px" }}>
        Search User
      </h1>

      <SearchBar setLoading={setLoading} setSearchedUser={setSearchedUser} />

      {searchedUsers !== undefined && !loading &&
        <div
          className="shadow-box admin-box"
          style={{ width: "74%", marginTop: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          ></div>
          <hr style={{ marginBottom: "20px" }} />
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Profile</th>
                  <th>Country</th>
                  <th>CreatedOn</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers?.map((user, index) => (
                  <tr key={index}>
                    <td>
                      {user.profileType === "ngo" ||
                        user.profileType === "impactOrg" ||
                        user.profileType === "business"
                        ? user.businessName
                        : user?.firstName + " " + user?.lastName}
                    </td>
                    <td>
                      {user.email}
                    </td>
                    <td>
                      {user.profileType}
                    </td>
                    <td>
                      {user.country}
                    </td>
                    <td>
                      {moment(user.createdDate).format("DD-MM-YYYY hh:mm A")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(searchedUsers?.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      }

      {loading &&  
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Loader />
        </div>
      }

    </div>
  )
}