import React from 'react'

export default function SortAndFilter({setSortAndFilter,sortAndFilter, users, setUsers}) {
    
    const selectHandler = (selectedFeature) => {
        setSortAndFilter(selectedFeature);
        let sortedUsers = [...users];
            
        if (selectedFeature === 'businessName') {
            sortedUsers.sort((a, b) => a.businessName.localeCompare(b.businessName));
        } else if (selectedFeature === 'walletPoint') {
            sortedUsers.sort((a, b) => b.wallet.walletPoint - a.wallet.walletPoint);
        }
        setUsers(sortedUsers);
    }

    return (
        <div>
            <select value={sortAndFilter} onChange={(e)=>selectHandler(e.target.value)}>
                <option disabled value="">Sort & Filter</option>
                <option value="businessName">A-Z</option>
                <option value="walletPoint">DM (high - low)</option>
            </select>
        </div>
    )
}