import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BarLoader } from "react-spinners";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Popover } from "antd";

const SendcoinsOpen = ({ user,showModal, closeModal, }) => {
  const [selectedNGO, setSelectedNGO] = useState(user);
  const [users, setUsers] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [currency, setCurrency] = useState({});
  const [formData, setFormData] = useState({
    amount: 0,
    mode: "",
    type: "",
    otp: "",
    note: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    setSelectedNGO(user);
  }, [user]);

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/allusers?email=${searchEmail}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (searchEmail) {
          setSelectedNGO(response.data.data[0]);
        } else {
          setSelectedNGO(null);
        }

        setUsers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [searchEmail]);

  const loadUser = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setUserData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadCoin = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/currency`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCurrency(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadUser();
    loadCoin();
  }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();

    if (userToken) {
      Swal.fire({
        title: `Are you sure you want to send $${formData.amount} to ${
          selectedNGO.businessName ||
          selectedNGO.firstName + " " + selectedNGO.lastName
        } ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#3ca151",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);

          if (userData?.profileType === "individual") {
            setFormData({ ...formData, type: "donation" });
          } else if (
            userData.profileType === "ngo" ||
            userData?.profileType === "impactOrg"
          ) {
            setFormData({ ...formData, type: "grant" });
          } else {
            setFormData({ ...formData, type: "gift" });
          }

          let data = JSON.stringify({
            paymentMode: formData.mode,
            amount: formData.amount / currency?.value,
            recipientUserId: selectedNGO?._id,
            transactionType: formData.type,
            email: userData.email,
            otp: formData.otp,
            currencyValue: currency?.value,
            note: formData.note,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_API}/sendpoints`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            data: data,
          };

          axios
            .request(config)
            .then(async (response) => {
              await Swal.fire({
                title: "Success!",
                confirmButtonColor: "#3ca151",
                text: "Transaction Successful!",
                icon: "success",
              });
              closeModal();
              window.location.reload();
            })
            .catch(async (error) => {
              await Swal.fire({
                title: "Error!",
                confirmButtonColor: "#3ca151",
                text: error.response.data.message,
                icon: "error",
              });
              closeModal();
              window.location.reload();
            });
        } else {
          setIsLoading(false);
        }
      });
    } else {
      await Swal.fire({
        title: "Error!",
        confirmButtonColor: "#3ca151",
        text: "Please login first to proceed further",
        icon: "warning",
      });
      window.location.href = "/";
    }
  };

  return (
    showModal && (
      <div className="modal-overlay">
        <div className="modal">
          <button className="close-button" onClick={closeModal}>
            x
          </button>

          <h2 style={{ marginTop: "0px" }}>Donate DM</h2>
          <form style={{ marginBottom: "20px" }} onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Search organisation by email"
              onChange={(e) => {
                setSearchEmail(e.target.value);
              }}
              value={searchEmail}
            />

            <p style={{ textAlign: "center",opacity:0.4 }}>
              ---------------OR---------------
            </p>
            {!searchEmail && (
              <select
                style={{
                  border: "none",
                  borderBottom: "1px solid grey",
                  outline: "none",
                }}
                onChange={(e) => setSelectedNGO(JSON.parse(e.target.value))}
                required
              >
                <option value="" selected>
                  Select Organisation*
                </option>
                {users?.map((u) => (
                  <option value={JSON.stringify(u)}>
                    {u.businessName
                      ? u.businessName
                      : `${u.firstName} ${u.lastName}`}
                  </option>
                ))}
              </select>
            )}
            {(searchEmail || selectedNGO?.businessName) && (
              <input
                type="text"
                disabled
                value={
                  selectedNGO.businessName
                    ? selectedNGO.businessName
                    : "Organisation not found"
                }
              />
            )}
            <label
              style={{ borderBottom: "1px solid grey", borderRadius: "5px" }}
            >
              $
              <input
                id="amount-input"
                style={{
                  border: "none",
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                }}
                type="number"
                placeholder=" Enter amount in Dollars*"
                value={
                  formData.amount > 0
                    ? formData.amount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                onChange={(e) =>
                  /^[0-9.]*$/.test(e.target.value) &&
                  setFormData({ ...formData, amount: e.target.value })
                }
                required
              />
            </label>
            <label
              style={{
                fontSize: "12px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              Approximate DM*
              <Popover
                placement="right"
                content={
                  <p
                    style={{
                      backgroundColor: "white !important",
                      color: "white",
                    }}
                  >
                    How the conversion is done?
                    <br />
                    1DM= ${currency?.value?.toFixed(3)}
                  </p>
                }
                trigger="hover"
              >
                <IoMdInformationCircleOutline size={20} color="green" />
              </Popover>
            </label>
            <input
              disabled
              value={formData.amount / currency?.value}
              type="number"
            />
            <textarea
              type="text"
              style={{ marginBottom: "10px", fontSize: "15px" }}
              placeholder="Enter a note (optional)"
              value={formData.note}
              onChange={(e) =>
                setFormData({ ...formData, note: e.target.value })
              }
            />

            <button
              disabled={!(selectedNGO?._id && formData.amount > 0) || isLoading}
              onClick={handleSubmit}
              type="button"
            >
              Send DM
            </button>
          </form>
          {isLoading && (
            <>
              <BarLoader cssOverride={{ margin: "auto" }} color="#3ca151" />
              <p style={{ textAlign: "center" }}>
                Please wait while your request is being processed.
              </p>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default SendcoinsOpen;
