import axios from "axios";

const axiosInstance = (userToken) => {
  return axios.create({
    baseURL: process.env.REACT_APP_LOCAL_API,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    maxBodyLength: Infinity,
  });
};

export default axiosInstance;