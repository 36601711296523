import React, { useState, useEffect } from 'react';
import useThrottle from '../../hooks/useThrottle';
import axiosInstance from '../../config/ProtectedAxios';

export default function SearchBar({setSearchedUser, setLoading}) {
    const [selectedOption, setSelectedOption] = useState("email");
    const userToken = localStorage.getItem("userToken");
    const [searchText, setSearchText] = useState("");
    const protectedAxiosInstance = axiosInstance(userToken)
    const throttledSearchText = useThrottle(searchText, 500);

    const searchTextHandler = (e) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        if (throttledSearchText) {
            const fetchData = async () => {
                try {
                    setLoading(true)
                    const res = await protectedAxiosInstance.post(
                        '/search',
                        {
                            email: (selectedOption === "email" ? searchText : ""),
                            firstName: (selectedOption === "first" ? searchText : ""),
                            lastName: (selectedOption === "last" ? searchText : "")
                        }
                    )
                    setSearchedUser(res.data)
                    setLoading(false)
                } catch (err) {
                    console.log(err)
                }
            };
            fetchData();
        }

        if(searchText.length <= 0){
            setSearchedUser(undefined)
        }

    }, [throttledSearchText, selectedOption]);

    return (
        <div className='search-container'>
            <input
                onChange={searchTextHandler}
                placeholder='Search...'
            />
            <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                <option value={"email"}>Email</option>
                <option value={"first"}>FirstName</option>
                <option value={"last"}>LastName</option>
            </select>
        </div>
    );
}
